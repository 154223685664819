import './Page.css';
import back from './assests/Back.png'
import leftimg from './assests/Meltozx3.gif'
import rightimg from './assests/0thMeltozx3.gif'
import centerimg from './assests/Kotodama Logo 25.gif'
import subcenterimg from './assests/Mint Now Button.png'
import logo from './assests/Solana Full.png'
import img1 from './assests/1K.png'
import img2 from './assests/2k.png'
import img3 from './assests/3k.png'
import img4 from './assests/4k.png'
import img5 from './assests/5k.png'
import img6 from './assests/6k.png'
import img7 from './assests/7k.png'
import img8 from './assests/8k.png'
import img9 from './assests/9k.png'
import img10 from './assests/10k.png'
import React from "react";
import styled from "styled-components";

import "./App.css";
import { useMemo } from "react";

import Home from "./Home";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Link } from 'react-router-dom';

const ValidWrap = styled.div`
  max-width: 550px;
  width: 100%;
  min-width: 100px;
  margin: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    font-family: Poppins;
  }
`

const CheckValidity = styled.form`

  display: flex;
  max-width: 550px;
  width: 100%;
  min-width: 100px;
  /* margin: 32px 16px; */
  input {
    outline-width: 0;
    border: 3px solid white;
    flex: 1;
    display: flex;
    font-size: 1.2em;
    /* width: 100%; */
    font-weight: 500;
    color: white;
    background: inherit;
    padding: 12px;
  
    
  }
  .check {
    font-size: 2vw;
    font-weight: 500;
   
    border-right: 3px solid white;
    color: black;
    padding: 7px 16px;
    border-left: 3px solid white;
  }
  
  @media(max-width: 650px) {
    flex-direction: column;
    max-width: 450px;
    .check {
      font-size: 8vw;
      border-left: 0px;
      border-top: 3px solid white;
    }
  }
  @media(max-width: 450px) {
    max-width: 320px;
  }
`

const Countdown = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 1000;
  font-size: 24px;
  color: white;
  text-align: center;
  font-family: Poppins;
  margin-bottom: 64px;

  
`;

const MintButton = styled.button`
display: flex;
// flex-direction: row;
justify-content: center;
align-items: center;
padding: 2px 36px;
font-family: Poppins;
//width: 250x;
//height: 76px;
background: #7877e6;

font-weight: 600;
color: white;
cursor: pointer;
font-size: 18px;
transition: 0.5s;
:hover {
  padding: 6px 40px;
  font-size: 24px;
}
 
`;


  

  

  

  
 
  const connection = new anchor.web3.Connection(process.env.REACT_APP_SOLANA_RPC_HOST);
  
  const startDateSeed =process.env.REACT_APP_CANDY_START_DATE
  
  const txTimeout = 30000; 
  
  const theme = createTheme({
      palette: {
          type: 'dark',
      },
      overrides: {
          MuiButtonBase: {
              root: {
                  justifyContent: 'flex-start',
              },
          },
          MuiButton: {
              root: {
                  textTransform: undefined,
                  padding: '12px 16px',
              },
              startIcon: {
                  marginRight: 8,
              },
              endIcon: {
                  marginLeft: 8,
              },
          },
      },
  });
function Page() {
    const endpoint = useMemo(() => clusterApiUrl(process.env.REACT_APP_SOLANA_NETWORK), []);

    const wallets = useMemo(
      () => [
          getPhantomWallet(),
          getSlopeWallet(),
          getSolflareWallet(),
          getSolletWallet(process.env.REACT_APP_SOLANA_NETWORK),
          getSolletExtensionWallet(process.env.REACT_APP_SOLANA_NETWORK)
      ],
      []
    );
  
  const [error, setError] = React.useState(false)
  const [showMint, setShowMint] = React.useState(false)
  const [value, setValue] = React.useState()

  const validList = [
    {
      id: 'hjfd768rhbnsdbtry48rk'
    }, 
    {
      id: 'd2s4dsdcds5sd6v7s4vc6'
    }, 
    {
      id: '1q2s3d42d3v42s3c4d34v'
    }, 
  ]

  const onSubmit = (e) => {
    e.preventDefault()
    const condition = Boolean(validList.find(data => data.id === value))
    if (condition) {
      setShowMint(true)
      setError(false)
    } else {
      setShowMint(false)
      setError(true)
    }
    console.log(condition)
  }

  const inputForm = (e) => {
    const wallet = e.target.value
    setValue(wallet)
    console.log(wallet)
  }
  return (
    <div className="App">
      {/* fixed back button */}
      <a style={{textDecoration:'none', color:'black'}} href="https://www.glyph-bound.com/">
      <div className="back">
        <img src={back} alt="" />
        <div className="text">Back</div>
      </div>
      </a>

      {/* Main screen */}
      <div className="main">
        
        {/* left Section */}
        <div className="left">
          <img src={leftimg} alt="" />
        </div>
        
        {/* Center Section */}
        <div className="center">
          <img className='logo' src={centerimg} alt="" />
          <p>As a security measure, the Glyph-Bound minting page is only accessible if your Solana deposit wallet address has been whitelisted.</p>
          
          {/* <ValidWrap>
          {
            showMint ? 
              "":
            <CheckValidity>
              <input  name='walletid' id='walletid' onChange={inputForm} type="text" placeholder='Enter wallet address to check for presale eligibility' />
              <button onClick={onSubmit} className='check input'>Check</button>
            </CheckValidity>
          }
          {error ? 
            <div 
            style={{ display: "flex", justifyContent: 'flex-start', flex: 1, width: '100%', margin: '6px 2px', color: 'red', fontSize: '1.2em' }} 
            className="message">ERROR: Wallet Address Not Found</div>
            : ''
          }
        </ValidWrap> */}
     
      
          <div style={{ display:'flex'}} id="demo" className="button__wrap">
            
            <img className='mintButton' src={subcenterimg} alt="" />
            <div className="mintText">
            <ThemeProvider theme={theme}>
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={true}>
              <WalletDialogProvider>
                <Home
                  candyMachineId={process.env.REACT_APP_CANDY_MACHINE_ID}
                  config={process.env.REACT_APP_CANDY_MACHINE_CONFIG}
                  connection={connection}
                  startDate={startDateSeed}
                  treasury={process.env.REACT_APP_TREASURY_ADDRESS}
                  txTimeout={txTimeout}
                />
              </WalletDialogProvider>
            </WalletProvider>
          </ConnectionProvider>
        </ThemeProvider>
              

            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="right">
          <img src={rightimg} alt="" />
        </div>
      </div>

      <div className="imglist">
        <img src={img1} alt="" />
        <img src={img2} alt="" />
        <img src={img3} alt="" />
        <img src={img4} alt="" />
        <img src={img5} alt="" />
        <img src={img6} alt="" />
        <img src={img7} alt="" />
        <img src={img8} alt="" />
        <img src={img9} alt="" />
        <img src={img10} alt="" />
      </div>

      <div className="footer">
        <div className="footer__text">
          Only 50 of each available
        </div>
      </div>
    </div>
  );
}

export default Page;
